import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px 38px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Pangolin', cursive;
`;

export const StyledButtonLink = styled.a`
  padding: 20px 38px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Pangolin', cursive;
  text-decoration: none;
`;


export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 32px;
  color: var(--secondary-text);
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-family: 'Pangolin', cursive;
`;

export const StyledRoundLink = styled.a`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 32px;
  color: var(--secondary-text);
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-family: 'Pangolin', cursive;
  margin: 0 24px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledPreview = styled.img`
  width: 42%;
  border-radius: 12px;
  margin-right: 32;
  @media (min-width: 767px) {
    max-width: 240px;
  }
`;

export const StyledPond = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 800px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`How many Polz are you minting?`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    MAX_MINT_AMOUNT: 20,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, there's a new ${CONFIG.NFT_NAME} in your wallet! Go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAX_MINT_AMOUNT) {
      newMintAmount = CONFIG.MAX_MINT_AMOUNT;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ 
          padding: 24, 
          backgroundColor: "var(--primary)", 
          // backgroundImage: "url(/config/images/pond.png)", 
          // backgroundSize: "1000px",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "bottom center",
        }}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              // backgroundColor: "var(--accent-semi)",
              // backgroundImage: "url(/config/images/pond.png)", 
              // backgroundSize: "1000px",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "bottom center",
              padding: "24 0",
              borderRadius: 24,
              // border: "3px dashed var(--secondary)",
              // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
                marginTop: 42,
              }}
            >
              {CONFIG.MAX_SUPPLY}
              <span style={{ color: "var(--secondary)"}}> / </span> 
              <span style={{ color: "var(--yellow)"}}>{CONFIG.MAX_SUPPLY}</span><br />
              <span style={{ color: "var(--secondary)", fontSize: 32 }}>SOLD OUT</span><br />
            </s.TextTitle>
            <img src="/config/images/bg.gif" />
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              View the {CONFIG.NFT_NAME} collection on
            </s.TextDescription>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <StyledButtonLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              OpenSea
            </StyledButtonLink>
            <s.SpacerSmall />
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>
            
            <s.SpacerSmall />
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 42
              }}
            >
              <span style={{ color: "var(--yellow)"}}>CryptoPolz</span> are baby <StyledLink target={"_blank"} href="https://cryptoadz.io">
                CrypToadz
              </StyledLink>.
            </s.TextDescription>
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "80%", marginTop: 64 }}>
              <StyledPreview src="/config/images/preview1.jpeg" style={{ marginRight: 32 }} />
              <StyledPreview src="/config/images/preview2.jpeg" />
            </s.Container> 
            <s.SpacerLarge />
            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "80%", marginBottom: 32 }}>
              <StyledPreview src="/config/images/preview3.jpeg" style={{ marginRight: 32 }} />
              <StyledPreview src="/config/images/preview4.jpeg" />
            </s.Container>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32
              }}
            >
              This is the prequel to the CrypToadz we all missed.
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32
              }}
            >
              All <span style={{ color: "var(--yellow)"}}>9696</span> CryptoPolz are 100% unique, randomly generated.
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32
              }}
            >
              <span style={{ color: "var(--yellow)"}}>
                Will you be there when they grow?
              </span>
            </s.TextDescription>
            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>

            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", margin: "32px 0" }}>
              <a href="https://metapond.io">
                <StyledLogo src="https://metapond.io/images/metapond-logo.png" />
              </a>
            </s.Container>

            <s.Container jc={"center"} ai={"center"} fd={"row"} style={{ width: "100%", margin: "32px 0" }}>
              <StyledRoundLink href={CONFIG.MARKETPLACE_LINK} target="_blank">
                <img src="/config/images/opensea.svg" alt="Follow @cryptopolznft on Twitter" />
              </StyledRoundLink>
              <StyledRoundLink href="https://twitter.com/cryptopolznft" target="_blank">
                <img src="/config/images/twitter.svg" alt="Follow @cryptopolznft on Twitter" />
              </StyledRoundLink>
              <StyledRoundLink href="http://discord.metapond.io" target="_blank">
                <img src="/config/images/discord.svg" alt="Join our Discord server" />
              </StyledRoundLink>
            </s.Container>

            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32,
                color: "#f65df5"
              }}
            >
              o~  o~  o~
            </s.TextDescription>

            <s.TextDescription
              style={{
                marginTop: 32,
                textAlign: "center",
                fontSize: 32
              }}
            >
              Smart Contract<br /> 
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} style={{ fontSize: 32 }}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>

            <StyledPond
              alt={"Pond"}
              src={"/config/images/pond.png"}
              style={{
                marginTop: 32,
              }}
            />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
